import React from "react";

const FarmingGame = ({ points, image, link, onclick }) => {
  return (
    <div className="bg-white/30  rounded-2xl p-2 space-y-8">
      <img src={image} alt="" className="mx-auto h-32" />
      <h3>{points} Points</h3>

      <button onClick={onclick}>
        <a
          href={link}
          className="inline-flex items-center justify-center h-12 px-6 transition duration-500 rounded-2xl shadow-md bg-color2 "
        >
          Claim Now
        </a>
      </button>
    </div>
  );
};

export default FarmingGame;
