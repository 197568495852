import React from "react";

const FarmingTasks = ({ image, text, points, link }) => {
  return (
    <div>
      <a
        href="../"
        className="bg-white/30 rounded-xl p-2 gap-4 flex justify-around items-center transition hover:w-80 duration-500 ease-in-out "
      >
        <div className="w-36">
          <img src={image} alt="" className="h-36" />
        </div>
        <div className="space-y-4">
          <p className="text-left">{text}</p>
          <div className="flex flex-col items-left space-y-2">
            <h6 className="text-left">{points} Points</h6>
            <a
              href={link}
              className="inline-flex items-center justify-center h-12 px-6 rounded-2xl shadow-md bg-color2 "
            >
              Claim
            </a>
          </div>
        </div>
      </a>
    </div>
  );
};

export default FarmingTasks;
