import React, { useState, useEffect, useMemo } from "react";
import { ConnectWallet, Web3Button, useContractRead, useContract, useAddress } from "@thirdweb-dev/react";
import { Navbar, H2Overlaptext } from "../Components";
import { farmingbg, ftb6 } from "../Assets";
import { apiFetch, numberWithCommas } from './utils';
import Countdown from './countdown';

const Builder = () => {
  const stakeContract = "0xDF96C1a4507Be5C117D7f082ecE1DB0da4E854a8"
  const nftContract = "0xC6643041B2396FBDF7Bf582619b73e9A3ea69dBB"
  const { contract } = useContract(nftContract);
  const [data, setData] = useState(null);
  const [fetchingStake, setFetchingStake] = useState(false);
  const [stakeQuantity, setStakeQuantity] = useState(0);
  const [unstakeQuantity, setUnstakeQuantity] = useState(0);
  const [refetched, setRefetched] = useState(1);
  const address = useAddress();

  const { data: isApproved, isLoading, refetch: refetchApproval } = useContractRead(contract, "isApprovedForAll", [address, stakeContract]);

  const url = new URL(window.location.href);
  const referrer = url.pathname.includes('/r/') ? url.pathname.split('/').filter(Boolean).pop() : "";

  const getStakeInfo = async () => {
    if (address) {
      setFetchingStake(true)
      
      const response = await apiFetch(`/stake/${address}?referrer=${referrer}`, {
        method: "GET"
      });

      if(response?.status === 200) {
        setRefetched(refetched + 1)
        setData(response?.data)

        const nftsToStake = response?.data?.nftsCount > 48 ? 48 : response?.data?.nftsCount;
        const nftsToUnstake = response?.data?.stakedCount > 48 ? 48 : response?.data?.stakedCount;
        setStakeQuantity(nftsToStake)
        setUnstakeQuantity(nftsToUnstake)
      }

      setFetchingStake(false)
    }
  }

  useEffect(() => {
    if (address) getStakeInfo()
  }, [address]);

  const memoizedData = useMemo(() => data, [data]);

  return (
    <div className="bg-black">
      <Navbar hasConnectButton />
      <section
        className="bg-no-repeat bg-cover bg-top min-h-screen"
        style={{
          backgroundImage: `url(${farmingbg})`,
        }}
      >
        <div className="pb-[100px] lg:pb-10">
          <div className="max-w-6xl px-4 pt-16 space-y-16 mx-auto md:px-24 lg:px-8 lg:py-20 text-center text-white ">
            <div className="space-y-8">
              <h4 className="text-[35px] mb-[50px] lg:text-[45px] font-bold lg:whitespace-nowrap px-[0px] text-white pb-5 capitalize">
                <H2Overlaptext text="Stake Your Builder's NFt" color="text-color2" />
              </h4>
              <p className="text-[15px] lg:w-[100%] pt-[20px] lg:pt-[0px] -mb-[20px] px-[40px] lg:px-0 lg:pb-[20px] text-white text-center font-[SuperFunky]">Receive a 5x bonus boost in airdrop points for the next 24 hours</p>
              <div className="!-m-[20px]">
                <Countdown date="2024-05-02T16:00:00" />
              </div>
            </div>
            <div className="text-white text-left space-y-8">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="h-max min-h-[230px] flex  flex-col justify-center items-center p-4 bg-white/30 lg:min-w-[353px] rounded-xl">
                  <h6 className="text-md text-center pb-4">Your NFT Portfolio</h6>
                  
                  {!address ? (
                    <ConnectWallet
                      style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "90%", background: "#00b046", borderRadius: 12 }}
                      theme="light"
                      networkSelector="open"
                      hideTestnetFaucet={true}
                      switchToActiveChain={true}
                      modalSize="wide"
                    />
                  ) : (
                    <>
                      <div className={`w-full h-full rounded-[inherit] ${memoizedData?.nftIds <= 0? "flex justify-center items-center" : "grid gap-2 grid-cols-3"}`}>
                        {memoizedData?.nftIds?.slice(0, 3)?.map(nft => 
                          <img alt="" 
                            className="w-full rounded-[inherit] shrink-0 h-[120px] lg:h-[100px]"
                            src={`https://etherpillar-builder.s3.eu-west-2.amazonaws.com/images/${nft}.png`}
                          />
                        )}
                      </div>

                      { memoizedData?.nftIds?.length > 3 && 
                        <p className="text-md font-[SuperFunky] text-center pt-4">{memoizedData?.nftIds?.length - 3} more...</p>
                      }
                    </>
                  )}
                </div>
                <div className="h-max min-h-[230px] flex  flex-col justify-center items-center p-4 bg-white/30 lg:min-w-[353px] rounded-xl">
                  <h6 className="text-md text-center pb-4">Your Staked NFTs</h6>
                  
                  {!address ? (
                    <ConnectWallet
                      style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "90%", background: "#00b046", borderRadius: 12 }}
                      theme="light"
                      networkSelector="open"
                      hideTestnetFaucet={true}
                      switchToActiveChain={true}
                      modalSize="wide"
                    />
                  ) : (
                    <>
                      {memoizedData?.stakedNftIds?.length === 0 && <h6 className="text-2xl">No stakes yet</h6>}
                        <div className="w-full h-full rounded-[inherit] grid gap-2 grid-cols-3">
                          {memoizedData?.stakedNftIds?.slice(0, 3)?.map(nft => 
                            <img alt="" 
                              className="w-full rounded-[inherit] shrink-0 h-[120px] lg:h-[100px]"
                              src={`https://etherpillar-builder.s3.eu-west-2.amazonaws.com/images/${nft}.png`}
                            />
                          )}
                        </div>

                        { memoizedData?.stakedNftIds?.length > 3 && 
                          <p className="text-md font-[SuperFunky] text-center pt-4">{memoizedData?.stakedNftIds?.length - 3} more...</p>
                        }
                    </>
                  )}
                </div>
                <div className="h-max min-h-[230px] flex  flex-col justify-center items-center p-4 bg-white/30 lg:min-w-[353px] rounded-xl">
                  <h6 className="text-md text-center pb-4">Stake Points earned</h6>
                  <AnimatedPointsDisplay numberWithCommas={numberWithCommas} memoizedData={memoizedData} />
                </div>
                <div className=" bg-white/30 flex flex-col justify-center lg:min-w-[353px] items-center rounded-xl p-8 space-y-4">
                  <h6 className="text-md text-center pb-2">Stake Your nft</h6>
                  {address && (
                    <QuantitySetter limit={memoizedData?.nftsCount ?? 0} quantity={stakeQuantity} setQuantity={setStakeQuantity} />
                  )}

                  {!address ? (
                    <ConnectWallet
                      style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "100%", background: "#00b046", borderRadius: 12 }}
                      theme="light"
                      networkSelector="open"
                      hideTestnetFaucet={true}
                      switchToActiveChain={true}
                      modalSize="wide"
                    />
                  ) : isApproved ? (
                    <Web3Button
                      contractAddress={stakeContract}
                      style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "100%", background: "#00b046", borderRadius: 12 }}
                      theme="light"
                      isLoading={fetchingStake || isLoading}
                      // action={async (contract) => {
                      //   if (memoizedData?.nftsCount >= stakeQuantity) {
                      //     const nftIds = memoizedData?.nftIds.slice(0, stakeQuantity);
                      //     await contract.call("stake", [nftIds])
                      //   } else {
                      //     alert("You don't have enough NFTs to stake")
                      //   }
                      // }}
                      onError={(err) => {
                        alert(err.reason?.toUpperCase() || "Uh uh! failed to unstake NFT");
                      }}
                      onSuccess={getStakeInfo}
                    >
                      Stake
                    </Web3Button>
                  ) : (
                    <Web3Button
                      contractAddress={nftContract}
                      style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "100%", background: "#00b046", borderRadius: 12 }}
                      theme="light"
                      isLoading={fetchingStake || isLoading}
                      action={async (contract) => {
                        await contract.call("setApprovalForAll", [stakeContract, address])
                      }}
                      onError={(err) => {
                        alert(err.reason?.toUpperCase() || "Uh uh! failed to unstake NFT");
                      }}
                      onSuccess={refetchApproval}
                    >
                      Approve
                    </Web3Button>
                  )}
                </div>
                <div className="opacity-30 pointer-events-none cursor-not-allowed bg-white/30 flex flex-col justify-center lg:min-w-[353px] items-center rounded-xl p-8 space-y-4">
                  <h6 className="text-md text-center pb-2">UnStake Your nft</h6>
                  {!address ? (
                    <ConnectWallet
                    style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "100%", background: "#02adab", borderRadius: 12 }}
                      theme="light"
                      networkSelector="open"
                      hideTestnetFaucet={true}
                      switchToActiveChain={true}
                      modalSize="wide"
                    />
                  ) : (
                    <>
                      <QuantitySetter limit={memoizedData?.stakedCount ?? 0} quantity={unstakeQuantity} setQuantity={setUnstakeQuantity} />
                      <Web3Button
                        contractAddress={stakeContract}
                        style={{ maxHeight: "50px", paddingTop: "24px", fontFamily: "SuperFunky", color: "white", paddingBottom: "24px", height: "48px", fontSize: "16px", minWidth: "100%", background: "#02adab", borderRadius: 12 }}
                        theme="light"
                        isLoading={fetchingStake || isLoading}
                        // action={async (contract) => {
                        //   if (memoizedData?.stakedCount >= unstakeQuantity) {
                        //     const nftIds = memoizedData?.stakedNftIds.slice(0, unstakeQuantity);
                        //     await contract.call("withdraw", [nftIds])
                        //   } else {
                        //     alert("You don't any staked NFTs to unstake")
                        //   }
                        // }}
                        onError={(err) => {
                          alert(err.reason?.toUpperCase() || "Uh uh! failed to unstake NFT");
                        }}
                        onSuccess={getStakeInfo}
                      >
                        Unstake
                      </Web3Button>
                    </>
                  )}
                </div>
                <div className="h-max min-h-[230px] flex  flex-col justify-center items-center p-4 bg-white/30 lg:min-w-[353px] rounded-xl">
                  <h6 className="text-md text-center pb-5">Get more NFTs</h6>
                  <a target="_blank" href="https://element.market/collections/etherpillar-builders-collection" className="flex justify-center items-center font-['SuperFunky'] text-[16px] h-[50px] w-[90%] rounded-[15px] shadow-md bg-color2 text-white">
                    Buy NFT Here
                  </a>
                </div>
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 max-w-1/4 mx-auto text-center">
                <div className="bg-black/30 space-y-1 rounded-xl p-5 h-[100px]">
                  <h6>{memoizedData?.stakedCount ?? 0}</h6>
                  <h6>Your Stakes</h6>
                </div>
                <div className="bg-black/30 space-y-1 rounded-xl p-5 h-[100px]">
                  <h6>{numberWithCommas(memoizedData?.totalStakedCount ?? 0)}/5000</h6>
                  <h6>All NFTs Stakes</h6>
                </div>
                <div className="bg-black/30 space-y-1 rounded-xl p-5 h-[100px]">
                  <h6>{numberWithCommas(memoizedData?.earnRate ?? 0)} Points</h6>
                  <h6>Earning amount</h6>
                </div>
                <div className="bg-black/30 space-y-1 rounded-xl p-5 h-[100px]">
                  <h6>15 minutes</h6>
                  <h6>Earning rate</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const QuantitySetter = ({ quantity, setQuantity, limit }) => {
  return (
    <div className="flex items-center">
    <button
      onClick={() => quantity && setQuantity(Math.max(1, quantity - 1))}
      className="px-3 text-[20px] py-1 bg-gray-200 hover:bg-gray-300 rounded-l"
    >
      <h6>-</h6>
    </button>
    <input
      type="text"
      value={quantity}
      onChange={(e) => setQuantity(Math.min(limit, Math.max(1, parseInt(e.target.value) || 1)))}
      className="w-full outline-none hover:ring-2 ring-white rounded px-3 py-3 text-[20px] bg-black/30 text-center"
    />
    <button
      onClick={() => quantity && setQuantity(Math.min(limit, quantity + 1))}
      className="px-3 text-[20px] py-1 bg-gray-200 hover:bg-gray-300 rounded-r"
    >
      <h6>+</h6>
    </button>
  </div>
  );
};

const AnimatedPointsDisplay = ({ memoizedData, numberWithCommas }) => {
  const [animatedPoints, setAnimatedPoints] = useState(memoizedData?.pointEarned ?? 0);

  const earnRatePerSecond = useMemo(() => {
    const pointsPerMinute = memoizedData?.earnRate ?? 0;
    return pointsPerMinute / 600;
  }, [memoizedData?.earnRate]);

  useEffect(() => {
    setAnimatedPoints(memoizedData?.pointEarned ?? 0);
  }, [memoizedData?.pointEarned]);

  useEffect(() => {
    if (memoizedData?.stakedCount > 0) {
      const interval = setInterval(() => {
        setAnimatedPoints((prevPoints) => prevPoints + earnRatePerSecond);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [earnRatePerSecond, memoizedData?.stakedCount]);

  return (
    <h6 className="text-4xl">
      {numberWithCommas(animatedPoints?.toFixed(2) ?? 0)}
    </h6>
  );
};

export default Builder;
