import React from "react";
import { Copy, PeopleFill, XCircleFill } from "react-bootstrap-icons";
import toast, { Toaster } from "react-hot-toast";

const ReferralModal = ({ isOpen, onClose }) => {
  const copyToClipboard = (e) => {
    e.preventDefault();
    const copyText = document.getElementById("copyText");
    copyText.select();
    document.execCommand("copy");
    toast.success("Invite link copied to clipboard!");
  };

  return (
    <div
      className={`fixed inset-0 overflow-y-auto z-20 ${isOpen ? "block" : "hidden"}`}
    >
      <Toaster />

      <div className="flex items-center justify-center min-h-screen">
        <div
          className="fixed inset-0 bg-color3 bg-opacity-50"
          onClick={onClose}
        ></div>

        <div className="relative bg-color2 rounded-lg shadow-lg max-w-xl w-full">
          <button
            className="absolute -top-10 right-4 text-gray-500"
            onClick={onClose}
          >
            {" "}
            <XCircleFill size={32} className="text-white" />
          </button>

          <div className=" p-4 rounded-2xl text-center flex flex-col lg:flex-row items-center justify-center gap-4">
            <div className="text-white flex items-center gap-2">
              <PeopleFill size={32} />

              <p>Referrals : 4</p>
            </div>
            <form
              className="flex items-center gap-2 flex-row"
              onSubmit={copyToClipboard}
            >
              <input
                type="text"
                value={`https://blastecosystem.com?invite=s`}
                id="copyText"
                readOnly
                placeholder="EVM Address"
                required
                className="flex-grow w-max h-12 px-4 text-neutral-900 transition duration-500 rounded-2xl appearance-none md:mr-2 md:mb-0 "
              />
              <button
                type="submit"
                onclick={copyToClipboard}
                className="inline-flex items-center justify-center h-12 px-2 text-color2 transition duration-500 rounded-2xl shadow-md bg-white "
              >
                <Copy size={32} />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
