import React from "react";
import { FarmingTab, Navbar } from "../Components";
import { farmingbg } from "../Assets";

const Farming = () => {
  return (
    <div className="bg-gradient-to-b from-black to-color2">
      <Navbar />
      {/* Points*/}
      <section
        className=" bg-no-repeat bg-contain bg-top flex justify-center items-start min-h-screen"
        style={{
          backgroundImage: `url(${farmingbg})`,
        }}
      >
        <div className="pb-16">
          <div className="px-4 py-16 space-y-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-center text-white ">
            <div className="space-y-8 pb-12">
              <h3 className=" text-4xl">Start Your Farming Journey</h3>
              <p className="max-w-2xl mx-auto">
                Play games to earn In-game points. Points accumulated through
                Ether Games will be converted to $ETP at a later date
              </p>
            </div>
            <div>
              <FarmingTab />
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default Farming;
