import React, { useState } from "react";
import { logo, omnilogo } from "../Assets";
import { ConnectWallet } from "@thirdweb-dev/react";
import { HouseDoorFill, XCircleFill, List } from "react-bootstrap-icons";

const Navbar = ({ hasConnectButton }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEthosDropdownOpen, setIsEthosDropdownOpen] = useState(false);
  const [isP2EDropdownOpen, setIsP2EDropdownOpen] = useState(false);

  const toggleEthosDropdown = () => {
    setIsEthosDropdownOpen(!isEthosDropdownOpen);
  };

  const toggleP2EDropdown = () => {
    setIsP2EDropdownOpen(!isP2EDropdownOpen);
  };


  return (
    <div className="bg-color2/50 relative z-20 ">
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="relative flex items-center justify-between">
          <a href="../" className="inline-flex items-center">
            <img src={omnilogo} alt="Logo" className="w-20" />
          </a>
          <ul className="flex items-center hidden space-x-12 lg:flex text-white">
            <li>
              <a
                href="../"
                className="inline-flex items-center justify-center h-12 px-6 tracking-wide"
              >
                <HouseDoorFill size={32} className="text-color2" />
              </a>
            </li>

            <li>
              <div className="relative">
                <button
                  onClick={toggleP2EDropdown}
                  className="font-medium tracking-wide transition-colors duration-200"
                >
                  P2E Games
                </button>
                {isP2EDropdownOpen && (
                  <div className="absolute mt-2 w-40 bg-color2 rounded-lg shadow-md ">
                    <ul className="space-y-2">
                      <li>
                        <a
                          href="https://omnigames.omnipillar.org"
                          className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                        >
                          Ether Jack
                        </a>
                      </li>
                      <li>
                        <a
                          href="../etherslots"
                          className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                        >
                          Ether Slots
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
            <li>
              <div className="relative">
                <button
                  onClick={toggleEthosDropdown}
                  className="font-medium tracking-wide transition-colors duration-200"
                >
                  Ether Ethos
                </button>
                {isEthosDropdownOpen && (
                  <div className="absolute mt-2 w-40 bg-color2 rounded-lg shadow-md">
                    <ul className="space-y-2">
                      <li>
                        <a
                          href="../genesis"
                          className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                        >
                          Genesis NFT
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                        >
                          Builder's NFT
                        </a>
                      </li>
                      {/* Add more sublinks as needed */}
                    </ul>
                  </div>
                )}
              </div>
            </li>
            <li>
              <a
                href="../#roadmap"
                className="font-medium tracking-wide transition-colors duration-200"
              >
                Roadmap
              </a>
            </li>
            <li>
              <a
                href="../farming"
                className="font-medium tracking-wide transition-colors duration-200"
              >
                Farming
              </a>
            </li>
            <li>
              <a
                href="../tokenomics"
                className="font-medium tracking-wide transition-colors duration-200"
              >
                Tokenomics
              </a>
            </li>
            {hasConnectButton && (
              <li>
                <ConnectWallet
                  className="inline-flex !font-['SuperFunky'] items-center justify-center !h-12 !px-6 !text-white transition duration-500 rounded-2xl !shadow-md !bg-color2"
                  theme={"dark"}
                  style={{ background: "white" }}
                  networkSelector="open"
                  hideTestnetFaucet={true}
                  switchToActiveChain={true}
                  modalSize="wide"
                  modalTitleIconUrl=""
                />
              </li>
            )}
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <List size={32} className="text-white" />
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <div className="p-5 bg-color1 border rounded shadow-sm ">
                  <div className="flex items-center justify-end mb-4">
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <XCircleFill size={32} className="text-color3" />
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href="../"
                          className="inline-flex items-center justify-center h-12 px-6 tracking-wide"
                        >
                          <HouseDoorFill size={32} className="text-color2" />
                        </a>
                      </li>
                      <li>
                        <div className="relative">
                          <button
                            onClick={toggleP2EDropdown}
                            className="font-medium tracking-wide transition-colors duration-200 relative z-30"
                          >
                            P2E Games
                          </button>
                          {isP2EDropdownOpen && (
                            <div className="absolute mt-2 w-40 bg-color2 rounded-lg shadow-md z-30">
                              <ul className="space-y-2">
                                <li>
                                  <a
                                    href="https://ethergames.etherpillar.org"
                                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                                  >
                                    Ether Jack
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="../etherslots"
                                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                                  >
                                    Ether Slots
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="relative">
                          <button
                            onClick={toggleEthosDropdown}
                            className="font-medium tracking-wide transition-colors duration-200"
                          >
                            Ether Ethos
                          </button>
                          {isEthosDropdownOpen && (
                            <div className="absolute mt-2 w-40 bg-color2 rounded-lg shadow-md">
                              <ul className="space-y-2">
                                <li>
                                  <a
                                    href="../genesis"
                                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                                  >
                                    Genesis NFT
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="../mint"
                                    className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 hover:border rounded"
                                  >
                                    Builder's NFT
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                      <li>
                        <a
                          href="../#roadmap"
                          className="font-medium tracking-wide transition-colors duration-200"
                        >
                          Roadmap
                        </a>
                      </li>
                      <li>
                        <a
                          href="../farming"
                          className="font-medium tracking-wide transition-colors duration-200"
                        >
                          Farming
                        </a>
                      </li>
                      <li>
                        <a
                          href="../whitelist-checker"
                          className="font-medium tracking-wide transition-colors duration-200"
                        >
                          Check Whitelist
                        </a>
                      </li>
                      <li>
                        <a
                          href="../tokenomics"
                          className="font-medium tracking-wide transition-colors duration-200"
                        >
                          Tokenomics
                        </a>
                      </li>
                      {hasConnectButton && (
                        <li>
                          <ConnectWallet
                            className="inline-flex !font-['SuperFunky'] items-center justify-center !h-12 !px-6 !text-white transition duration-500 rounded-2xl !shadow-md !bg-color2"
                            theme={"dark"}
                            style={{ background: "white" }}
                            networkSelector="open"
                            hideTestnetFaucet={true}
                            switchToActiveChain={true}
                            modalSize="wide"
                            modalTitleIconUrl=""
                          />
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
