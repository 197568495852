import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="pb-16 text-white">
        <div className="px-4 py-16 space-y-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-center ">
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <div className="">
              <h3 className="text-2xl md:text-3xl lg:text-4xl">Join Our</h3>
              <h3 className="text-4xl md:text-5xl lg:text-6xl">Community</h3>
            </div>
          </div>
          <p>Building NFTs, Play-to-Earn Games and DeSoFi (DeFi + SocialFi)</p>
          <div>
            <span className="font-sans text-color9 gap-4 flex flex-wrap justify-center items-center" >
            <div><a href="https://opensea.io/collection/etherpillar-genesis-nft?tab=items">Opensea</a></div> | 
            <div><a href="https://discord.com/invite/kMQYaJTGd6/">Discord</a></div> | 
            <div><a href="https://twitter.com/EtherPillarNFT">Twitter</a></div> | 
            <div><a href="../">Debank</a></div> | 
            <div><a href="https://mirror.xyz/0x5E086EF4a71Bbd1C438561ec485308A67e97930F">Mirror</a></div> | 
            <div><a href="https://zealy.io/cw/etherpillars/questboard">Zealy</a></div>  | 
            <div><a href="../">Farcaster</a></div> 
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
