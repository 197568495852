import React from "react";
import {
  buildersimg,
  gamesimg,
  genesisbg,
  genesisbg2,
  herobg,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10, p11,
  roadmapimg,
} from "../Assets";
import {
  Footer,
  H1Overlaptext,
  H2Overlaptext,
  Navbar,
  PartnersImg,
} from "../Components";

const Home = () => {
  return (
    <div>
      <Navbar className="" />
      {/* Hero */}
      <section
        className=" bg-color2/90 bg-no-repeat bg-cover bg-center h-full lg:h-dvh "
        style={{
          backgroundImage: `url(${herobg})`,
        }}
      >
        <div className="pb-16">
          <div className="">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 space-y-8">
                <H1Overlaptext text="Providing Support for the Ether Community" />
                <p className=" md:text-lg text-white pt-80 md:pt-40 lg:pt-40">
                  Building NFTs, Play-to-Earn Games and DeSoFi (DeFi + SocialFi)
                </p>

                {/* <a
                  href="/mint"
                  className="inline-flex items-center justify-center h-12 px-16 py-6  text-white bg-color1/90 border-b-8 border-b-color2 border-r-4 border-r-color2 duration-0 hover:duration-500 ease-in-out hover:px-6"
                >
                  Mint Builder NFT
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Games */}
      <section className=" bg-gradient-to-b from-color1 to-color3" id="games">
        <div className="pb-16">
          <div className="flex flex-col lg:flex-row lg:mx-24">
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
              <H2Overlaptext text="Our Games" color="text-color6" />
              <div className="max-w-xl mb-10  lg:max-w-2xl md:mb-12 space-y-8">
                <p className=" md:text-lg text-white pt-12 md:pt-20 lg:pt-40">
                  The Ether Jack is a simple casino card game drawn between a
                  player and a dealer. The player bets against the dealer on a
                  round, and if won, doubles his stake as winnings for that
                  round.
                </p>
                <a
                  href="../etherjack"
                  className="inline-flex items-center justify-center h-12 px-6 transition duration-500 border-2 border-color1 rounded-2xl shadow-md bg-color2/0 "
                >
                  <h6 className="text-color1">Ether Jack</h6>
                </a>
                <p className=" md:text-lg text-white pt-4">
                  The Ether Slots game is a jackpot/lottery game that allows
                  players to input desirable amounts into the virtual slot
                  machine to get rewards. Simply put; a player wins a large
                  prize by matching specific numbers or symbols based on their
                  preset and multiplier of their bets.
                </p>
                <a
                  href="../etherslots"
                  className="inline-flex items-center justify-center h-12 px-6 transition duration-500 border-2 border-color1 rounded-2xl shadow-md bg-color2/0 "
                >
                  <h6 className="text-color1">Ether Slots</h6>
                </a>
              </div>
            </div>
            <div className="px-4 lg:p-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
              <img src={gamesimg} alt="ether kack" />
            </div>
          </div>
        </div>
      </section>

      {/* NFTs and Roadmap and Partners*/}
      <section className=" bg-gradient-to-b from-color4 to-color5">
        {/* Builders NFT */}
        <div className="pb-16">
          <div className="flex flex-col-reverse lg:flex-row lg:mx-24 items-center">
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <img src={buildersimg} alt="builders" className="" />
            </div>
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
              <H2Overlaptext text="Builders NFT" color="text-color6" />
              <div className="max-w-xl mb-10  lg:max-w-2xl md:mb-12 space-y-8">
                <p className=" md:text-lg text-color-3 pt-12 md:pt-20 lg:pt-40">
                  The Omni Pillar Builder's Collection is a 5000 NFT supply — a
                  collection created to represent the wise founders/builders who
                  have built and are still building the pillars and foundation
                  of Ethereum It simply portrays the individual builders in the
                  Ethereum community
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Genesis NFT */}
        <div
          className="pb-16 bg-no-repeat bg-cover bg-center "
          style={{
            backgroundImage: `url(${genesisbg})`,
          }}
        >
          <div className="flex flex-col lg:flex-row lg:mx-24 items-center">
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
              <H2Overlaptext text="Genesis NFT" color="text-color7" />
              <div className="max-w-xl mb-10  lg:max-w-2xl md:mb-12 space-y-8">
                <p className=" md:text-lg text-white pt-12 md:pt-20 lg:pt-40">
                  The Omni Pillar Genesis Collection is a 999 NFT supply, with
                  each pillar uniquely scattered across the globe owned by
                  notable Ethereum enthusiasts all for free These unique pillars
                  are the core beginning, the very Genesis of everything Ether
                  Pillars. They symbolise our strong support for the Ethereum
                  community
                </p>
              </div>
            </div>
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <img src={genesisbg2} alt="builders" />
            </div>
          </div>
        </div>

        {/*  Roadmap*/}
        <div className="pb-16" id="roadmap">
          <div className="flex flex-col-reverse lg:flex-row lg:mx-24 items-center">
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <img src={roadmapimg} alt="builders" />
            </div>
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
              <H2Overlaptext text="Our Roadmap" color="text-color8" />
              <div className="max-w-xl mb-10  lg:max-w-2xl md:mb-12 space-y-8">
                <p className=" md:text-lg text-white pt-12 md:pt-20 lg:pt-40">
                  The Omni Pillar Roadmap enbodies a large scale of activities
                  to be carried out for the benefit of the project. However, to
                  simplify our major focus, this is the summarised roadmap:
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Partners */}
        <div className="pb-16">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-center ">
            <h3 className="text-white text-4xl pb-10 lg:pb-20">Our Partners</h3>
            <div className=" md:mb-12 gap-4 lg:gap-20 flex flex-wrap items-center justify-center ">
            <PartnersImg logo={p1} name='Mintify' />
              <PartnersImg logo={p2} name='Blast Alpha' />
              <PartnersImg logo={p3} name='xBlast' />
              <PartnersImg logo={p4} name='Blastarians' />
              <PartnersImg logo={p5} name='Blast Ecosystem' />
              <PartnersImg logo={p6} name='Blastnsdomains' />
              <PartnersImg logo={p7} name='Flashbitxys' />
              <PartnersImg logo={p8} name='Element Market' />
              <PartnersImg logo={p9} name='Peanut Games' />
              <PartnersImg logo={p10} name='Blastopedia' />
              <PartnersImg logo={p11} name='Blast Quake' />
            </div>
          </div>
        </div>

        <Footer/>
      </section>


    </div>
  );
};

export default Home;
