import { Navbar } from "../Components";
import { farmingbg } from "../Assets";
import { Toaster } from "react-hot-toast";
import { Copy } from "react-bootstrap-icons";
import React, { useMemo, useState, useEffect } from 'react';
import { ConnectWallet, useOwnedNFTs, useAddress, useContract, useContractMetadata } from "@thirdweb-dev/react";
import dayjs from 'dayjs';
import { apiFetch, copyToClipboard, numberWithCommas } from './utils';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const Genesis = () => {
  const address = useAddress();
  const contractQuery = useContract("0x9BEF4E8A232D2F08608adF84f520FbE0cdf4E768");
  const contractMetadata = useContractMetadata(contractQuery.contract);
  const userNFTs = useOwnedNFTs(contractQuery.contract, address);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [canClaim, setCanClaim] = useState(false);
  const [claimDetails, setClaimDetails] = useState(null);

  const nfts = useMemo(() => {
    const nfts = userNFTs?.data;
    return {
      nftCount: nfts?.length || 0,
      nftIds: nfts?.map((nft) => nft?.metadata?.id) || [],
    };
  }, [userNFTs]);

  const claimPoints = async () => {
    if (!address) return true;
    const url = new URL(window.location.href);
    const referrer = url.pathname.includes('/farming') ? '' : url.pathname.split('/').filter(Boolean).pop();

    setIsClaiming(true);

    await apiFetch("/claim", {
      body: {
        address,
        ...(referrer && { referrer })
      },
      method: "POST"
    });

    setIsClaiming(false);
  }

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await apiFetch("/claim", {
          query: { address },
          method: "GET"
        });

        setClaimDetails(response)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (address) {
      fetchData();
    } else {
      setClaimDetails(null)
    }
  }, [address, isClaiming]);

  const calculatePointPerClaim = (nftCount) => {
    const pointMap = new Map([
      [1, 999],
      [2, 1498.5],
      [3, 1498.5],
      [4, 1498.5],
      [5, 2247.75],
      [6, 2247.75],
      [7, 2247.75],
      [8, 2247.75],
      [9, 2247.75],
    ]);

    return pointMap.get(nftCount) || 2997;
  }

  const lastClaimedDate = dayjs(claimDetails?.updated_at);
  const currentDate = dayjs(claimDetails?.current_date);
  const is24Hours = currentDate.diff(lastClaimedDate, 'hours') >= 24;
  const daysPassed = Math.max(1, currentDate.diff(lastClaimedDate, 'day'));
  const pointsPerNFT = !claimDetails?.valid_nft_count ? calculatePointPerClaim(nfts?.nftCount) : claimDetails?.pointsPerNFT;
  const pendingPoints = !is24Hours ? 0 : pointsPerNFT * nfts?.nftCount * daysPassed;

  useEffect(() => {
    setCanClaim(pendingPoints > 0);
  }, [pendingPoints]);

  return (
    <div className="bg-black">
      <Toaster />
      <Navbar hasConnectButton />
      {/* Points*/}
      <section
        className=" bg-no-repeat bg-cover bg-top flex justify-center items-start min-h-screen"
        style={{
          backgroundImage: `url(${farmingbg})`,
        }}
      >
        <div className="pb-16">
          <div className="px-4 py-16 gap-16 mx-auto md:px-24 lg:px-24 lg:py-20 text-center text-white ">
            <div className="space-y-8 pb-12">
              <h3 className=" text-4xl">Earn points with your Genesis NFT</h3>
            </div>
            <div className="flex flex-col lg:flex-row gap-16">
              <div className="text-white text-left space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Total Points</h6>
                    <h6 className="text-2xl">{!claimDetails?.points ? 0 : numberWithCommas(claimDetails?.points?.toFixed(1)) || 0}</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">NFT Count</h6>
                    <h6 className="text-2xl">{claimDetails?.valid_nft_count ?? nfts?.nftCount}</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Next Claim</h6>
                    <h6 className="text-2xl">{isLoading || !claimDetails?.updated_at ? "------" : <Countdown claimDetails={claimDetails} />}</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Unclaimed Point</h6>
                    <h6 className="text-2xl">{!pendingPoints ? 0 : numberWithCommas(pendingPoints?.toFixed(1)) || 0}</h6>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 text-center">
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Referral Count</h6>
                    <h6 className="text-2xl">{claimDetails?.referral_count ?? 0}</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Referral Points</h6>
                    <h6 className="text-2xl">{!claimDetails?.referral_points ? 0 : numberWithCommas(claimDetails?.referral_points?.toFixed(1)) || 0}</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-left md:col-span-2">
                    <h6 className="text-sm">Refer and Earn</h6>
                    <form
                      className="flex items-center gap-2 flex-row"
                      onSubmit={copyToClipboard}
                    >
                      <input
                        type="text"
                        value={claimDetails?.referral_id ? `https://omniplllar.org/?invite=${claimDetails?.referral_id}` : 'Fetching...'}
                        id="copyText"
                        readOnly
                        placeholder="Referral link"
                        required
                        className="w-full h-12 px-4 bg-black/30  rounded-2xl "
                      />
                      <button
                        type="submit"
                        onclick={copyToClipboard}
                        className="inline-flex items-center justify-center h-[45px] w-[50px] px-2 text-color2 transition duration-500 rounded-2xl shadow-md bg-white "
                      >
                        <Copy size={25} />
                      </button>
                    </form>
                    <p>
                      Get 5% of whatever your referee claims. Make your first
                      NFT points claim to get your referral ID.
                    </p>
                  </div>
                </div>
              </div>

              <div className="basis-4/12 space-y-8 order-first lg:order-none">
                <div className="text-center space-y-4">
                  {(!address) ? (
                    <ConnectWallet
                      theme="dark"
                      style={{ width: "100%", color: "#000000", fontSize: 18, height: 50 }}
                      className="bg-white text-[#000000] text-[20px] w-full h-[50px] px-5 rounded-[15px] font-[600]"
                      btnTitle="Connect Wallet"
                      dropdownPosition={{
                        side: "bottom",
                        align: "start"
                      }}
                    />
                  ) : (
                    <>
                      {!isLoading && !userNFTs?.isLoading ? (
                        <>
                          {isClaiming ? (
                            <button className="bg-white flex justify-center items-center text-[#000000] w-full text-[20px] h-[50px] px-5 rounded-[10px] font-[600]">
                              <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 3a9 9 0 1 0 9 9" />
                              </svg>
                              Claiming...
                            </button>
                          ) : (
                            <>
                              {nfts?.nftCount > 0 ? (
                                <>{canClaim ? (
                                  <button onClick={claimPoints} className="w-full font-['SuperFunky'] text-[20px] inline-flex items-center justify-center h-[50px] px-6 rounded-[15px] shadow-md bg-color2">
                                    Claim
                                  </button>
                                ) : (
                                  <p className="flex items-baseline justify-center">
                                    <span className="text-[25px] font-bold font-['SuperFunky'] text-[#ffffff]">Await Next Claim</span>
                                  </p>
                                )}</>
                              ) : (
                                <p className="flex items-baseline justify-center">
                                  <span className="text-[25px] font-['SuperFunky'] font-bold text-[#ffffff]">NOT NFT HOLDER</span>
                                </p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <button className="bg-white flex justify-center items-center text-[#000000] w-full text-[20px] h-[50px] px-5 rounded-[10px] font-[600]">
                          <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 3a9 9 0 1 0 9 9" />
                          </svg>
                          Loading...
                        </button>
                      )}
                    </>
                  )}
                  <p>
                    Only NFTs not listed will be rewarded. <br /> Hold your
                    omniplllar NFTs to accumulate airdrop points.
                  </p>
                </div>
                <div className="bg-white/30 h-[330px] rounded-xl p-1 space-y-4 text-center">
                  <img alt="" className="h-full w-full rounded-[inherit]" src={userNFTs?.data?.[1]?.metadata?.image  || "https://bafybeiewf2vscbdju22zkcgeizm6dnza3m6nex4u66udd2jjlfohbwwt2y.ipfs.w3s.link/OmniPillar%20Twitter%20logo%20white.png"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Countdown = ({ claimDetails }) => {
  const calculateTimeUntilTarget = (claimDetails) => {
    const targetTime = dayjs(claimDetails?.next_updated_at);
    const currentTime = dayjs(claimDetails?.current_date);
    const timeDiff = targetTime.diff(currentTime, 'second');
    return Math.max(0, timeDiff);
  };

  const [time, setTime] = useState(calculateTimeUntilTarget(claimDetails));

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [time]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return (
    <>
      {time < 1 ? (
        <>Now</>
      ) : (
        <>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</>
      )}
    </>
  )
};

export default Genesis;
