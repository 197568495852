import crypto from 'crypto-js';
import axios from 'axios';
import toast from "react-hot-toast";

export const THIRDWEB_CLIENT_ID = "fa34a1ac0daf305170d4f6ab3dfb88c8";
const API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InJsbHF1NGJpNzl4bjNwN281bHd0anZxMWNkanh3OTE2OTEyNDc4MzY3MTgiLCJpYXQiOjE2OTEyNDc4MzYsImV4cCI6MTcyMjgwNTQzNiwiYXVkIjoiQVBJIiwiaXNzIjoiTG9sbGlwb3AifQ.SemRmdDvNthFRCMpvSw1JcKQeC9QGRzYAa0oEdPXe-4"
const BASE_URL = "https://etherpillar-api.up.railway.app/v1"
const SECURE_SEED = "jhfjdu&*&8huehjd783*(£8i5HHGjdj";

function generateKeyFromSeed(seed) {
  return crypto.HmacSHA256("secure", seed).toString(crypto.enc.Hex);
}

const toQueryString = (params) => {
  return Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
}

export const copyToClipboard = (e) => {
    e.preventDefault();
    const copyText = document.getElementById("copyText");
    copyText.select();
    document.execCommand("copy");
    toast.success("Invite link copied to clipboard!");
  };

const getKeys = async () => {
  const response = await axios.get(`${BASE_URL}/key`);

  const trackerKey = response?.data;
  const seed = trackerKey + SECURE_SEED;
  const clientKey = generateKeyFromSeed(seed);

  return {
    trackerKey,
    clientKey
  }
}

export const numberWithCommas = (numb) => {
  if(!numb) return numb;
  const parts = numb?.toString()?.split(".");
  parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts?.join(".");
};

export const apiFetch = async (endpoint, options) => {
  const keys = await getKeys();

  const fetchOptions = {
    ...{
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
        'x-client-key': keys.clientKey,
        'x-tracker': keys.trackerKey,
        'Sec-Fetch--Site': "cross-site"
      },
    },
    ...options
  };

  if (options.body) {
    fetchOptions.body = JSON.stringify(options.body);
  }

  let url = `${BASE_URL}${endpoint}`;

  if (options.query) {
    const queryString = toQueryString(options.query);
    url = `${url}?${queryString}`;
  }

  try {
    const response = await window.fetch(url, fetchOptions);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Something went wrong');
    }

    const responseData = await response.json();
    return responseData;

  } catch (err) {
    console.log(err.message)
  }
}

export const shortenString = (value, length = 4) => {
  if (!value) return '';
  if (length >= value?.length) return value;
  return `${value.slice(0, length + 2)}...${value.slice(-length)}`;
}

export const compareStrings = (str1, str2) => {
  return str1?.toLowerCase() === str2?.toLowerCase();
}