import React from "react";
import { ftb1, ftb2, ftb3, ftb4, ftb5, ftb6} from "../Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const FarmingTier = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className=" m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb1} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
        <div>
          <div className=" m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb2} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
        <div>
          <div className=" m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb3} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
        <div>
          <div className=" m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb4} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
        <div>
          <div className=" m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb5} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
        <div>
          <div className="m-4 bg-white/30 rounded-2xl p-4 lg:p-8 space-y-8">
            <p>Lorem Ipsum is simply dummy text of the printing</p>
            <img src={ftb6} alt="" className="mx-auto h-40" />
            <h3>500 Points</h3>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default FarmingTier;
