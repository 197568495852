import React from "react";
import { H2Overlaptext, NavbarEtherJack } from "../Components";
import { etherjackbg, etherjackimg } from "../Assets";
import ProgressBar from "@ramonak/react-progress-bar";

const EtherJack = () => {
  return (
    <div>
      <NavbarEtherJack />
      {/* EtherJack */}
      <section
        className=" bg-no-repeat bg-cover bg-center h-full "
        style={{
          backgroundImage: `url(${etherjackbg})`,
        }}
      >
        <div className="pb-16">
          <div className="text-center">
            <a
              href="/"
              className="inline-flex items-center justify-center h-12 px-6 lg:w-1/2 text-white transition duration-500 rounded-2xl shadow-md bg-color2 "
            >
              <h6>Stake Amount : No Bets Placed</h6>
            </a>
          </div>

          <div className="flex justify-center">
            <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md">
              <img src={etherjackimg} alt="ether jack" className="max-w-full" />
            </div>
          </div>
        </div>
      </section>

      {/* Leaderboard */}
      <section id="">
        <div className="pb-16" id="leaderboard">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
              <H2Overlaptext text="Leaderboard" color="text-color2" />
              <div className="max-w-xl mb-10  lg:max-w-4xl md:mb-12 space-y-8 pt-12">
                <div className="p-8 my-8 flex flex-col border justify-center overflow-x-auto overflow-y-auto">
                  <table className="w-full table-auto text-color3">
                    <thead>
                      <tr className="border-b">
                        <th className="p-2">Wallet</th>
                        <th className="p-2">Referral Points</th>
                        <th className="p-2">Gameplay Points</th>
                        <th className="p-2">Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-2">01</td>
                        <td className="p-2">Malcolm Lockyer</td>
                        <td className="p-2">Malcolm Lockyer</td>
                        <td className="p-2">1961</td>
                      </tr>
                      <tr>
                        <td className="p-2">02</td>
                        <td className="p-2">The Eagles</td>
                        <td className="p-2">The Eagles</td>
                        <td className="p-2">1972</td>
                      </tr>
                      <tr>
                        <td className="p-2">03</td>
                        <td className="p-2">Earth, Wind, and Fire</td>
                        <td className="p-2">Earth, Wind, and Fire</td>
                        <td className="p-2">1975</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Acheivements */}
      <section id=""> 
        <div className="pb-16 " id="achievements">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
              <H2Overlaptext text="Acheivements" color="text-color2" />
              <div className="max-w-xl mb-10  lg:max-w-4xl md:mb-12 space-y-8 pt-12">
                <div className="p-2 lg:p-8 my-8 flex flex-col border justify-center overflow-x-auto overflow-y-auto">
                  <div className="grid md:grid-cols-2 gap-8">
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={1}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                        
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={20}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={10}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={40}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={30}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                      />
                    </div>
                    <div className="text-center">
                      <p className="text-color3">Initiate (0 out of 100 Gameplay Points)</p>
                      <ProgressBar
                        completed={60}
                        bgColor='#ffffff'
                        baseBgColor='#007A78'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default EtherJack;
