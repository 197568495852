import React from "react";

const H2OverlapText = ({text, color}) => {
  return (
    <div className="">
      <div className="relative">
        {/* First text layer */}
        <h2 className="w-full text-white mb-6 text-3xl lg:text-5xl md:mx-auto absolute top-0 left-0 z-10 uppercase">
          {text}
        </h2>

        {/* Second text layer */}
        <h2 className="w-full text-color2 mb-6 text-3xl lg:text-5xl md:mx-auto absolute top-1 left-1 uppercase" >
          <div className={color}>{text}</div>
        </h2>
      </div>
    </div>
  );
};

export default H2OverlapText;
