import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThirdwebProvider } from "@thirdweb-dev/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Arbitrum } from "@thirdweb-dev/chains";
import { THIRDWEB_CLIENT_ID } from './Pages/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
const Blast = {
  "chain": "ETH",
  "chainId": 81457,
  "explorers": [
    {
      "name": "Blastscan",
      "url": "https://blastscan.io",
      "standard": "EIP3091"
    },
    {
      "name": "Blast Explorer",
      "url": "https://blastexplorer.io",
      "standard": "EIP3091"
    },
    {
      "name": "dexguru",
      "url": "https://blast.dex.guru",
      "standard": "EIP3091",
      "icon": {
        "url": "ipfs://bafybeifc2h3x7jgy4x4nmg2m54ghbvmkfu6oweujambwefzqzew5vujhsi",
        "width": 400,
        "height": 400,
        "format": "jpg"
      }
    }
  ],
  "faucets": [],
  "features": [],
  "icon": {
    "url": "ipfs://bafybeifc2h3x7jgy4x4nmg2m54ghbvmkfu6oweujambwefzqzew5vujhsi",
    "width": 400,
    "height": 400,
    "format": "jpg"
  },
  "infoURL": "https://blast.io",
  "name": "Blast",
  "nativeCurrency": {
    "name": "Ether",
    "symbol": "ETH",
    "decimals": 18
  },
  "networkId": 81457,
  "parent": {
    "type": "L2",
    "chain": "eip155-1",
    "bridges": [
      {
        "url": "https://blast.io/en/bridge"
      }
    ]
  },
  "redFlags": [],
  "rpc": [
    "https://rpc.blast.io",
    "https://rpc.ankr.com/blast",
    "https://blastl2-mainnet.public.blastapi.io",
    "https://blast.blockpi.network/v1/rpc/public",
    "https://blast.din.dev/rpc"
  ],
  "shortName": "blastmainnet",
  "slug": "blast",
  "testnet": false
}

const isBlastMint = ["/mint", "/stake"].includes(window.location.pathname);
const activeChain = isBlastMint ? Blast : Arbitrum;
const supportedChains = isBlastMint ? [Blast] : [Arbitrum];

root.render(
  <React.StrictMode>
    <ThirdwebProvider
      clientId={THIRDWEB_CLIENT_ID}
      activeChain={activeChain}
      supportedChains={supportedChains}
      autoConnect={true}
      dAppMeta={{
        name: "Etherpillar",
        description: "Powering the future of web3 with NFTs, Play-to-Earn Games and DeSoFi.",
        logoUrl: "https://d391b93f5f62d9c15f67142e43841acc.ipfscdn.io/ipfs/bafybeidegspfztn5lucchy4u43l45ydjym7qs4s25datr5vklrydy6qfvy/vdG2EsS2_400x400.jpg",
        url: "https://Etherpillar.xyz",
        isDarkMode: true,
      }}
    >
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);

reportWebVitals();
