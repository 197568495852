import React, { useState } from 'react';
import { Navbar } from "../Components";
import { farmingbg } from "../Assets";
import { apiFetch } from './utils';

const WhitelistChecker = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [whitelistDetails, setWhitelistDetails] = useState();
  const [address, setAddress] = useState('');

  const handleCheckWhitelist = async () => {
    if (!address) return;
    setIsLoading(true);

    try {
      const response = await apiFetch("/whitelist-checker", {
        query: { address },
        method: "GET"
      });

      setWhitelistDetails(response?.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false);
      setWhitelistDetails(null)
    }
  }

  return (
    <div className="bg-black">
      <Navbar />
      <section
        className=" bg-no-repeat bg-cover bg-top min-h-screen"
        style={{
          backgroundImage: `url(${farmingbg})`,
        }}
      >
        <div className="lg:max-w-[60%] px-4 py-12 space-y-10 lg:space-y-14 mx-auto md:px-24 lg:px-8 lg:py-20 text-center text-white ">
          <div className="space-y-7">
            <h3 className=" text-4xl">Check Builder's Mint Elegibility</h3>
            <p className="max-w-2xl text-[20px] mx-auto">
              Check if your address is on the Etherpillar's Builders NFT minting whitelist.
            </p>
          </div>

          <div className=" bg-white/30 w-[95%] lg:w-[80%] mx-auto flex flex-col justify-center items-center rounded-xl p-[30px] lg:p-[40px] space-y-4 py-[40px]">
            <h6 className="text-[20px] text-center pb-2">Enter wallet address</h6>
            <input
              type="text"
              onBlur={(e) => setAddress(e.target.value)}
              className="w-full outline-none hover:ring-2 ring-white rounded-[10px] px-3 py-3 text-[20px] bg-black/30 text-center"
            />
            <button 
              onClick={handleCheckWhitelist}
              className="w-full bottom">
              <div
                href="../"
                className="inline-flex w-full items-center justify-center h-[55px] px-6 transition duration-500 rounded-[10px] shadow-md bg-color2 "
              >
                <h6>Check my spot</h6>
              </div>
            </button>

            <div className={`${isLoading || whitelistDetails ? 'pt-4' : ''}`}>
              {isLoading ? (
                <h6 className="flex text-white items-center space-x-[10px] text-[15px] rounded-full p-[10px] lg:py-[2px]">
                  <svg className="w-[25px] lg:w-[25px] shrink-0 aspect-square animate-spin" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="3" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3a9 9 0 1 0 9 9" />
                  </svg>
                  <span className="text-[15px] pr-[5px] text-left">Checking, please wait!</span>
                </h6>
              ) : (
                <>
                  {whitelistDetails ? (
                    <>
                      {whitelistDetails?.whitelisted ? (
                      <h6 className="flex text-[#00b341] bg-white items-center space-x-[8px] text-[15px] rounded-full p-[10px] lg:py-[2px]">
                        <svg className="w-[40px] lg:w-[30px] shrink-0 aspect-square animate-pulse" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" fill="currentColor" />
                        </svg>
                        <span className="text-[15px] pr-[5px] text-left">Congratulations, you're whitelisted</span>
                      </h6>
                    ) : (
                      <h6 className="flex text-[#ff2825] bg-white items-center space-x-[8px] text-[15px] rounded-full p-[10px] lg:py-[2px]">
                        <svg className="w-[40px] lg:w-[30px] shrink-0 aspect-square animate-pulse" width="44" height="44" viewBox="0 0 24 24" strokeWidth="2" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" strokeWidth="0" fill="currentColor" />
                        </svg>
                        <span className="text-[15px] pr-[5px] text-left">Oh no!, you're not whitelisted</span>
                      </h6>
                    )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>

        <p className="text-[18px] px-2 lg:-mt-8 text-white font-medium text-center font-[SuperFunky]">*Genesis holders are automatically whitelisted</p>
      </section>
    </div>
  );
};

export default WhitelistChecker;