import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { BackTop } from "antd";
import { Builder, ComingSoon, WhitelistChecker, EtherJack, EtherSlots, Mint, Farming, Genesis, Home, NoPage, Tokenomics } from "./Pages";

function App() {
  return (
    <div className="App scroll-smooth bg-gradient-to-b from-color1 to-color2">

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/etherjack" element={<ComingSoon />} />
          <Route path="/etherslots" element={<ComingSoon />} />
          <Route path="/farming" element={<Farming />} />
          <Route path="/genesis" element={<Genesis />} />
          {/* <Route path="/mint" element={<Mint />} /> */}
          <Route path="/stake" element={<Builder />} />
          <Route path="/whitelist-checker" element={<WhitelistChecker />} />
          <Route path="/tokenomics" element={<Tokenomics />} />
          <Route path="/coming-soon" element={<ComingSoon/>} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>

      <BackTop />
    </div>
  );
}

export default App;
