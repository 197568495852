import React, { useState } from "react";
import {
  //comment,
  farmsocialsimg,
  fg1,
  fg2,
  fg3,
  ft1,
  ft2,
  ft3,
  ft4,
  ft5,
  ft6,
  ftimg,
  goldchest,
  //like,
  //retweet,
 // tag,
} from "../Assets";
import FarmingTier from "./FarmingTier";
import FarmingTasks from "./FarmingTasks";
//import FarmingTwitter from "./FarmingTwitter";
import FarmingGame from "./FarmingGame";


const Tab = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  

  return (
    <div className=" mx-auto space-y-8 -m-20">
      <div className="max-w-2xl mx-auto flex justify-around border-b border-color2">
        <button
          className={`${
            activeTab === 1
              ? "text-white border-b-2  border-white"
              : "text-color2  "
          } py-2 px-4`}
          onClick={() => handleTabChange(1)}
        >
          <h3>In-game Points</h3>
        </button>
        <button
          className={`${
            activeTab === 2
              ? "text-white border-b-2  border-white"
              : "text-color2  "
          } py-2 px-4`}
          onClick={() => handleTabChange(2)}
        >
          <h3>Social Points</h3>
        </button>
      </div>
      <div className="mt-4">
        {activeTab === 1 && (
          <div className=" grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 p-8">
            <FarmingGame points="500" image={fg1} link="../" onclick="../" />
            <FarmingGame points="500" image={fg2} link="../" onclick="../" />
            <FarmingGame points="500" image={fg3} link="../" onclick="../" />
          </div>
        )}
        {activeTab === 2 && (
          <div className="flex flex-col justify-between items-center">
            <div className="flex flex-col md:flex-row pb-32 gap-4 items-center">
              <div className="mx-auto space-y-4">
                <h3>Total Referrals</h3>
                <div className="w-48 border-white border py-2 rounded-3xl ">
                  <h3 className="text-2xl">12</h3>
                </div>
              </div>
              <div>
                <img src={farmsocialsimg} alt="farm socials" className="w-32"/>
              </div>

              <div className="mx-auto space-y-4">
                <h3>Points</h3>
                <div className="w-48 border-white border py-2 rounded-3xl ">
                  <h3 className="text-2xl">67,378,41</h3>
                </div>
              </div>
            </div>

            {/* twitter engage */}
            <section className=" b">
              {/* twitter share */}
              <div className="pb-16">
                <div className="">
                  <div className="space-y-16 text-center text-white">
                    <h3 className=" text-4xl">Engage On Twitter</h3>
                    <img src={ftimg} alt="twitter" className="mx-auto h-64" />
                    {/*
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 lg:gap-8 p-8  mx-auto">
                      <FarmingTwitter
                        image={tag}
                        title="Tag Ether Pillar"
                        link="../"
                      />
                      <FarmingTwitter
                        image={comment}
                        title="Comment"
                        link="../"
                      />
                      <FarmingTwitter image={like} title="Like" link="../" />
                      <FarmingTwitter
                        image={retweet}
                        title="Retweet"
                        link="../"
                      />
                    </div>
                  */}
                  </div>
                </div>
              </div>

              {/* task */}
              <div className="pb-16">
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                  <div className="space-y-16 text-center text-white">
                    <h3 className=" text-4xl">Tasks</h3>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                      <FarmingTasks
                        image={ft1}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                      <FarmingTasks
                        image={ft2}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                      <FarmingTasks
                        image={ft3}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                      <FarmingTasks
                        image={ft4}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                      <FarmingTasks
                        image={ft5}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                      <FarmingTasks
                        image={ft6}
                        text="Follow our partner on Twitter"
                        points="500"
                        link="./"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* teir bonus */}
              <div className="pb-16">
                <div className="px-4 py-16 mx-auto lg:px-8 lg:py-20">
                  <div className="space-y-16 text-center text-white">
                    <h3 className=" text-4xl">Tier Bonus</h3>
                    <div className="grid grid-cols-1 ">
                      <FarmingTier
                        text="Lorem Ipsum is simply dummy text of the printing"
                        image={goldchest}
                        points="500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tab;
