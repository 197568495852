import React from "react";

const H1OverlapText = ({text}) => {
  return (
    <div className="">
    <div className="relative">
      {/* First text layer */}
      <h1 className="text-white mb-6 text-4xl md:mx-auto leading-relaxed absolute top-0 left-0 z-10">
        {text}
      </h1>

      {/* Second text layer */}
      <h1 className="text-color2 mb-6 text-4xl md:mx-auto leading-relaxed text-shadow-sm shadow-color2 absolute top-1 left-1">
        {text}
      </h1>
    </div>
  </div>
  );
};

export default H1OverlapText;
