import React from "react";

const PartnersImg = ({logo, name}) => {
  return (
    <div>
      <div className=" border border-white rounded-xl p-4 w-40 h-48 flex flex-col items-center justify-center gap-4">
        <img src={logo} alt="partners" className="w-24 rounded-2xl" />
        <p className="text-white">{name}</p>
      </div>
    </div>
  );
};

export default PartnersImg;
