import React from "react";
import { Navbar } from "../Components";
import { farmingbg, tokenomics } from "../Assets";

const Tokenomics = () => {

  return (
    <div className="bg-black">
      <Navbar />
      {/* Points*/}
      <section
        className=" bg-no-repeat bg-cover bg-top flex justify-center items-start min-h-screen"
        style={{
          backgroundImage: `url(${farmingbg})`,
        }}
      >
        <div className="pb-16">
          <div className="px-4 py-16 gap-16 mx-auto md:px-24 lg:px-24 lg:py-20 text-center text-white ">
            <div className="space-y-8 pb-12">
              <h3 className=" text-4xl">Tokenomics</h3>
              <p className="max-w-2xl mx-auto">
                Play games to earn In-game points. Points accumulated through
                Ether Games will be converted to $ETP at a later date
              </p>
            </div>
            <div className="">
              <div className=" p-2 md:p-8 text-white rounded-2xl text-left flex flex-col justify-center space-y-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Token Name</h6>
                    <h6 className="text-2xl">Ether Pillar Token</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Ticker</h6>
                    <h6 className="text-2xl">$ETP</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Token Type</h6>
                    <h6 className="text-2xl">Utility</h6>
                  </div>
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Token Total Supply</h6>
                    <h6 className="text-2xl">1 Billion</h6>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={tokenomics}
                    alt="tokenomics"
                    className="w-[600px] "
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">ETP Utilities Governance</h6>
                    <p className="">Shape the future through decentralized voting</p>
                  </div>{" "}
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Staking Rewards</h6>
                    <p className="">Earn
                    passive income by securing the network</p>
                  </div>{" "}
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Play-to-Earn Gaming</h6>
                    <p className="">Have fun and earn tokens while gaming</p>
                  </div>{" "}
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Community Incentives</h6>
                    <p className="">Get rewarded for active participation</p>
                  </div>{" "}
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Partnerships & Integrations</h6>
                    <p className="">Explore cross-platform utility</p>
                  </div>{" "}
                  <div className=" bg-white/30 rounded-xl p-8 space-y-4 text-center">
                    <h6 className="text-sm">Exclusive Access</h6>
                    <p className="">Enjoy
                    special features and events.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tokenomics;
